img{
  width: 100%;
  height: 250px;
  margin-bottom: -5px;
}
.image{
  background-color: #000000;
  padding: 0;
  margin: 0;
}
.card-container {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  background-color: #0EB1D2;
  border-radius: 15px;
  margin: 1rem;
  height: 280px;
  color: #ffffff;
  width: 300px;
  padding-bottom: 25px;
  transform: translateZ(0);
  transition: transform .25s ease-out;
}
.card-container:hover {
  transform: scale(1.05);
}
.card-container p{
  margin-left: 8px;
  margin-right: 8px;
}
a{
  text-decoration: none;
  color: #ffffff;
}
